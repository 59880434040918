<template>
  <el-dialog
             title="提示"
             :visible.sync="showModel"
             width="6rem"
             :close-on-click-modal="false"
             :before-close="close">
    <div class="box_ms">
      <div class="head_box">
        <div class="tils">棒棒糖网咖超级服</div>
        <i style="display: block;" class="el-icon-close clos_iso"></i>
      </div>
      <div class="box_tabl">
        <el-table :data="dataList" height="100%">
          <el-table-column label="存档" prop="name" align="center"></el-table-column>
          <el-table-column label="在线" prop="des" align="center"></el-table-column>
          <el-table-column label="详情" align="center">
            <template slot-scope="scope">
              <el-button type="primary" style="border-radius: 0;">启动</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'serveList',
  data () {
    return {
      showModel: false,
      dataList: [
        {
          name: '存档1',
          on_file: 10
        },
        {
          name: '存档1',
          on_file: 10
        },
        {
          name: '存档1',
          on_file: 10
        },
        {
          name: '存档1',
          on_file: 10
        },
        {
          name: '存档1',
          on_file: 10
        },
        {
          name: '存档1',
          on_file: 10
        },
        {
          name: '存档1',
          on_file: 10
        }, {
          name: '存档1',
          on_file: 10
        }, {
          name: '存档1',
          on_file: 10
        }
      ]
    }
  },
  methods: {
    close () {

    }
  }
}
</script>
<style scoped lang="less">
/deep/.el-dialog .el-dialog__header,
/deep/.el-dialog .el-dialog__body,
/deep/.el-dialog .el-dialog__footer {
  padding: 0;
}

/deep/.el-dialog .el-dialog__header {
  display: none;
}

/deep/.el-table tbody .el-table__cell {
  background-color: #333333 !important;
  color: rgba(255, 255, 255, .7);
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条的背景区域的内阴影*/
  /*滚动条的背景区域的圆角*/
  background-color: #333333 !important;
  /*滚动条的背景颜色*/
}

.box_ms {
  background-color: #333333;

  .head_box {
    background-color: #4d4d4d;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: .2rem;
    justify-content: space-between;
    height: .54rem;
    box-sizing: border-box;
    padding: 0 .2rem;

    .clos_iso {
      &:hover {
        cursor: pointer;
        color: #ffba00;
      }
    }
  }

  .box_tabl {
    box-sizing: border-box;
    height: 4.5rem;
  }
}</style>