<template>
  <div class="downPage">
    <div class="item_btns">
      <div class="tls">游戏微端下载</div>
      <div class="des">下载即可秒完游戏，无需下载游戏</div>
      <el-button class="btn_sk" type="primary" @click="downLoad(1)">
        <div class="i_box">
          <img class="dow_icon" src="../assets/downicon.png">
          <div class="btn_font">下载游戏微端</div>
        </div>
      </el-button>
    </div>
    <div class="item_btns msz">
      <div class="tls">私服服务端下载</div>
      <div class="des">搭建自己的私服，一键部署即可开服</div>
      <el-button class="btn_sk" type="primary" @click="downLoad(2)">
        <div class="i_box">
          <img class="dow_icon" src="../assets/servericon.png">
          <div class="btn_font">下载私服服务端</div>
        </div>
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "downPage",
  data () {
    return {

    }
  },
  methods: {
    downLoad (type) {
      if (type == 1) {
        window.open('https://xemoss.wangkaguanli.com/CloudStart/release/CloudInstall.rar')
      } else {
        window.open('https://xemoss.wangkaguanli.com/palworldserver/release/palworldLaunch.rar')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.downPage {
  width: 100%;
  height: calc(100% - 1.68rem);
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;

  .item_btns {
    width: 4.8rem;
    height: 3.6rem;
    box-sizing: border-box;
    border: .02rem solid #3ab7ff;

    .tls {
      font-size: .4rem;
      color: #3ab7ff;
      margin-top: .7rem;
    }

    .des {
      font-size: .18rem;
      color: #fff;
      margin-top: .2rem;
    }

    .btn_sk {
      width: 3.2rem;
      height: .66rem;
      border: 0;
      display: block;
      margin: 0 auto;
      margin-top: .4rem;

      .i_box {
        display: flex;
        align-items: center;
        justify-content: center;

        .dow_icon {
          width: .36rem;
          height: .34rem;
        }

        .btn_font {
          font-size: .2rem;
          margin-left: .1rem;
        }
      }
    }

    &.msz {
      .tls {
        font-size: .4rem;
        color: #ffba00;
      }

      .btn_sk {
        background-color: #ffba00;
      }

      border: .02rem solid #ffba00;
      margin-left: .8rem;
    }
  }
}</style>