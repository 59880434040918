<template>
  <div class="inxdeBox">
    <div class="box_inof">
      <div class="mu_tiles" move>
        <div class="tils">幻兽帕鲁盒子</div>
        <div class="login_btn" @click="loginUser" v-if="version >= 32">
          <template v-if="!login">
            <i class="el-icon-user-solid"></i>
            <div class="text_login">登录</div>
          </template>

          <template v-else>
            <i class="el-icon-user-solid"></i>
            <div class="text_login">注销</div>
          </template>
        </div>

        <i style="display: block;cursor: pointer;margin-left: .2rem;" :style="{marginLeft:version >= 32?'20px':'auto'}" @click="minWin"
           class="el-icon-minus clos_iso"></i>
        <i style="display: block;cursor: pointer;margin-left: .2rem;" @click="closeWin"
           class="el-icon-close clos_iso"></i>

      </div>
      <div class="head_box">
        <img class="log_icon" src="../assets/logo.png" />
        <div class="input_box">
          <!-- <form @submit="getData" style="width: 100%;"> -->
          <input maxlength="20" @keyup.enter="getData" tabindex="-1" placeholder="请输入私服名字" v-model="searchServe"
                 class="input_search" />
          <!-- </form> -->
          <el-button class="el_btn" @click="getData" type="primary" tabindex="-1">
            <div class="i_box">
              <!-- <img class="dow_icon" src="../assets/downicon.png"> -->
              <div class="btn_font">服务器检索</div>
            </div>
          </el-button>
        </div>
      </div>
      <div class="tab_list">
        <div class="tab_item" @click="setTab(item.key)" :class="{ 'is_ck': item.key == tabIndex && !start }"
             v-for="(item, index) in tabList" :key="item.key">
          {{ item.label }}</div>
      </div>

      <template v-if="tabIndex != -1">
        <div class="s_tabl"
             element-loading-text="加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"
             v-loading="loading">
          <el-table :data="dataList" @row-click="setRowInfo" height="100%" highlight-current-row
                    @sort-change="filterList">
            <el-table-column label="服务器名称" prop="name" align="center">
              <template slot-scope="scope">
                <div style="color: #ffba00;">{{ scope.row.name }}</div>
              </template>
            </el-table-column>
            <el-table-column label="服务器描述" prop="des" align="center">

              <template slot-scope="scope">
                <div style="text-align: left;" v-html="scope.row.des.replace(/\r?\n/g, '<br>')"></div>
              </template>
            </el-table-column>
            <el-table-column label="当前在线" prop="person" align="center" sortable="custom"></el-table-column>
            <el-table-column label="版本" prop="ver" align="center" sortable="custom"></el-table-column>
            <el-table-column label="线路" prop="network" align="center" sortable="custom"></el-table-column>
            <el-table-column label="开服时间" prop="start_time" align="center" sortable="custom">
              <!-- <template slot-scope="scope">
                <div>{{ setTime(scope.row.start_time) }}</div>
              </template> -->
            </el-table-column>
            <!-- <el-table-column label="存档" prop="on_file" align="center" sortable="custom"></el-table-column> -->
            <el-table-column label="详情" align="center">

              <template slot-scope="scope">
                <el-button type="primary" style="border-radius: 0;width: 1rem;" tabindex="-1"
                           @click="showLogin(scope.row, false)">进入游戏</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="page_box">
          <el-pagination
                         @current-change="nextPage"
                         :current-page="page"
                         :page-size="size"
                         background
                         layout="prev, pager, next"
                         :total="count">
          </el-pagination>
        </div>
      </template>
      <downPage v-if="tabIndex == -1"></downPage>
      <div class="item_msg_info">
        <div class="tills">
          <div class="t1_s">当前选择</div>
          <!-- <div class="t2_s">当前选择</div> -->
        </div>
        <div class="fgx"></div>
        <div class="serve_box">
          <div class="serve_name">{{ siteItem.name }}</div>
          <div class="serve_desc">{{ siteItem.des }}</div>
        </div>
        <div class="fgx"></div>
        <div class="online_box">
          <div class="label" v-show="siteItem.person">当前在线</div>
          <div class="num_size">{{ siteItem.person ? siteItem.person + '人' : '-' }}</div>
        </div>
        <div class="start_box" @click="gameEnd" v-if="gameIng">结束游戏</div>
        <div class="start_box" @click="showLoginItem" v-else>开始游戏</div>
        <div class="start_box kslz" @click="showQR">分享给朋友</div>
      </div>
    </div>

    <setting ref="setting" :freeState="freeState" :gameIng="gameIng" :voiceKey="voiceKey" @setKeys="keyMode = true"
             @setFree="setFree"></setting>
    <!-- <serveList></serveList> -->
    <userlogin v-if="showLoginModel" :win-obj="winObj" :version="version" @start="startWin"
               @close="showLoginModel = false"
               :login-type="loginType"
               @login="setLogin">
    </userlogin>
    <qrModel v-if="showQrModel" :siteItem="siteItem" @close="showQrModel = false"></qrModel>
    <upload v-if="uplaodLoading"></upload>
    <winGameStart v-if="start" @close="closeStart" @restart="restartApp"></winGameStart>
    <keyMode :defaultKey="voiceKey" v-if="keyMode" @close="keyMode = false" @submitKey="submitKey"></keyMode>
  </div>
</template>

<script>
import setting from '../components/setting'
import keyMode from '../components/keyMode'
import http from '../request'
import serveList from '../components/serveList'
import downPage from '../components/downPage'
import userlogin from '../components/userlogin'
import qrModel from '@/components/qrModel'
import upload from '../components/upload'
import winGameStart from '@/components/winGameStart'
export default {
  name: 'HelloWorld',
  components: {
    serveList, downPage, userlogin, upload, qrModel, winGameStart, setting, keyMode
  },
  data () {
    return {
      version: 0,//版本
      account: '',//登录的账号密码
      password: '',
      gameIng: false,//游戏是否在游玩
      webSocket: null,
      uplaodLoading: false,
      keyMode: false,//设置语音键
      voiceKey: '',//语音键
      freeState: false,//自由麦状态
      tinInver: null,
      drive: '',
      loading: false,
      searchServe: '',
      tabIndex: 3,
      showLoginModel: false,//显示登录框
      showQrModel: false,//显示分享窗口
      port: null,
      localist: '',//本地缓存的ids列表
      start: false,
      count: 0,
      size: 10,
      page: 1,
      sortType: 'start_time',
      sort: 'desc',
      dataList: [],
      winObj: {},
      host: '',
      initData: false,//是否初始化过数据
      login: false,//是否登录
      loginType: false,//登录类型
      siteItem: {
        name: "请选择服务器",
        des: '',
        person: 0,
        id: null,
        addr: '',
        addr_port: ''
      },
      tabList: [
        { label: '本网吧自有私服', key: '3' },
        { label: '网吧服务器', key: '0' },
        { label: '私有服务器', key: '1' },
        { label: '玩过的服务器', key: '2' }
      ],
    }
  },
  beforeCreate () {
    document.getElementsByTagName('html')[0].style.fontSize = '100px'
  },
  mounted () {
    let that = this

    window.clientJS2 = function (obj) {
      let json = JSON.parse(obj)
      let data = json.data
      console.log('clientJS2', obj)
      if (json.c == 'search') {
        that.searchServe = data.key
        that.tabIndex = 0
        that.getData()
        return
      }

      if (json.c == 'login') {
        that.showLogin(json.data)
        return
      }

      if (json.c == 'gameend') {
        that.gameIng = false
        return
      }

      if (json.c == 'gamestart') {
        that.gameIng = true
        that.$nextTick(() => {
          that.$refs.setting.startShow()
        })
        // this.$refs.setting.startShow()
        return
      }

      //账号元整
      if (json.c == 'runend') {
        if (data.code != 0) {
          that.$message({
            message: '游戏启动失败',
            type: 'warning'
          })
        }
        if (data.code == 0) {
          that.gameIng = true
          that.$refs.setting.startShow()
        }
        that.start = false
        // console.log('that.start',that.start)
        that.showLoginModel = false
        return
      }

      if (json.c == 'loginend') {
        console.log('loginend', data.code)
        if (data.code == 2) {
          that.$message({
            message: '账号已存在，账户密码错误',
            type: 'warning'
          })
        } else if (data.code == -1) {
          that.$message({
            message: '网络错误，请稍后重试',
            type: 'warning'
          })
        } else if (data.code != 0) {
          //登录失败
          that.$message({
            message: '登录失败',
            type: 'warning'
          })
        } else {
          //登录成功
          that.setUser()
        }

        that.start = false
        that.showLoginModel = false
      }
    }
    //加载成功回调一次给C段 叫C段调用方法
    if (!!window.serverJS) {
      localStorage.getItem
      this.login = !!(localStorage.getItem('wxOpenId') || '')
      this.version = window.serverJS(JSON.stringify({ 'c': 'conf', 'a': 'version' })) || 0
      let voice = window.serverJS(JSON.stringify({ 'c': 'conf', 'a': 'voice' }))
      console.log('voice', voice, this.version)
      !!voice ? voice = JSON.parse(voice) : voice = { data: { key: 'R', free: true } }
      this.voiceKey = voice.data.key
      this.freeState = voice.data.free

      this.gameIng = window.serverJS(JSON.stringify({ 'c': 'gamestatus' }))
      this.host = window.serverJS(JSON.stringify({ 'c': 'conf', 'a': 'frp' }))

      if (this.gameIng) {
        this.$refs.setting.startShow()
      }


    }
    $('#move').show()
    // document.getElementById('move').style.display = 'block'
    // this.selectWeb(1)
    this.getList()
  },

  methods: {
    loginUser () {
      if (!this.login) {
        this.loginType = true
        this.showLoginModel = true
      } else {
        this.$message({
          message: '退出成功',
          type: 'success'
        })
        localStorage.setItem('wxOpenId', '')
        this.login = false
      }

    },
    setLogin () {
      this.login = true
    },
    startWin (obj) {
      this.start = true
      this.account = obj.user
      this.password = obj.pwd
    },
    gameEnd () {
      this.gameIng = false
      window.serverJS(JSON.stringify({ c: 'call', a: 'closegame' }))
    },
    closeStart () {
      this.start = false
      this.showLoginModel = false
    },
    showQR () {
      if (this.siteItem.id == null) {
        this.$message({
          message: '请选择需要分享的服务器',
          type: 'warning'
        })
        return
      }
      this.showQrModel = true
    },
    restartApp () {
      this.start = false
      this.showLoginItem()
    },
    showLoginItem () {

      if (!!this.siteItem.id) {
        this.showLogin(this.siteItem, false)
      } else {
        this.$message({
          message: '请选择需要启动的服务器',
          type: 'warning'
        })
      }
    },
    showLogin (opt, type = false) {
      if (this.gameIng) {
        this.$message({
          message: '游戏正在运行中..',
          type: 'warning'
        })
        return
      }
      let gameStae = window.serverJS(JSON.stringify({ c: 'call', a: 'run' }))
      console.log('call run', gameStae)
      if (gameStae == 0) {
        this.$message({
          message: '没有寻找到帕鲁客户端，请先下载安装',
          type: 'warning'
        })
        return
      }

      if (gameStae == 1) {
        this.$message({
          message: '正在寻找帕鲁客户端,请稍后...',
          type: 'warning'
        })
        return
      }

      if (gameStae == 2) {
        this.$message({
          message: '请先关闭正在运行的帕鲁客户端',
          type: 'warning'
        })
        return
      }
      //内网地址
      this.winObj['ip'] = opt.ip || ''

      //外网frp地址
      this.winObj['host'] = opt.host || opt.addr || ''

      //外网端口 内网通用
      this.winObj['port'] = opt.port || opt.addr_port || ''

      //语音地址端口
      this.winObj['voiceport'] = opt.voiceport || opt.voice_port || ''

      //内网地址
      this.winObj['apiport'] = opt.apiport || opt.api_port || ''

      //frp hash串
      this.winObj['hash'] = opt.hash || opt.hash || ''

      //服务器id
      this.winObj['id'] = opt.id || ''
      this.loginType = type
      this.showLoginModel = true
    },
    setRowInfo (obj) {
      this.siteItem = obj
    },
    closeWin () {
      window.serverJS(JSON.stringify({ c: 'call', a: 'close' }))
    },
    minWin () {
      window.serverJS(JSON.stringify({ c: 'call', a: 'min' }))
    },
    nextPage (val) {
      if (this.tabIndex != 2) {
        this.page = val
        this.localist = ''
        this.getList()
      } else {
        console.log('玩过的服务器 本地记录')
        this.getLocalData()
      }
    },
    getData (e) {
      if (!!e) {
        e.preventDefault()
      }
      if (this.initData && this.tabIndex == 3) {
        return
      }
      if (this.tabIndex != 2) {
        this.page = 1
        this.localist = ''
        this.getList()
      } else {
        console.log('玩过的服务器 本地记录')
        this.getLocalData()
      }
    },
    filterList (opt) {
      this.sortType = opt.prop
      this.sort = opt.order == 'ascending' ? 'asc' : 'desc'
      this.page = 1
      if (this.tabIndex != 2) {
        this.localist = ''
        this.getList()
      } else {
        this.getLocalData()
      }
    },
    getLocalData () {
      this.localist = (JSON.parse(localStorage.getItem('localId')) || []).join(',')
      if (this.localist == '') {
        this.dataList = []
        return
      }
      this.getList()
    },
    getList () {
      if (this.loading || this.start) {
        return
      }
      this.loading = true
      http({
        url: '/servers/get-servers',
        data: {
          hash: this.tabIndex == 3 ? this.host : '',
          page: this.page,
          name: this.searchServe,
          size: this.size,
          type: this.tabIndex,
          sort_type: this.sortType,
          ids: this.localist,
          sort: this.sort
        }
      }
      ).then(res => {
        this.loading = false
        if (res.data.length == 0 && !!this.searchServe) {
          this.$message({
            message: '当前关键词无相关结果',
            type: 'warning'
          })
          return
        }
        this.dataList = res.data
        this.count = res.ext.total
      })
    },
    setTab (key) {
      if (key == this.tabIndex || this.start) {
        return
      }
      this.searchServe = ''
      this.loading = false
      this.tabIndex = key
      this.start = false
      this.page = 1
      if (this.tabIndex != 2) {
        this.localist = ''
        this.getList()
      } else {
        this.getLocalData()
      }
    },
    submitKey (val) {
      let flag = window.serverJS(JSON.stringify({ c: 'conf', a: 'setvoice', data: { key: val, free: false } }))
      if (flag) {
        this.$message({
          message: '设置成功',
          type: 'success'
        })
        this.voiceKey = val
        this.keyMode = false
      } else {
        this.$message({
          message: '设置失败',
          type: 'warning'
        })
      }
    },
    setFree () {
      let state = !this.freeState
      let flag = window.serverJS(JSON.stringify({ c: 'conf', a: 'setvoice', data: { key: this.voiceKey, free: state } }))
      if (flag) {
        this.$message({
          message: '设置成功',
          type: 'success'
        })
        this.freeState = state
      } else {
        this.$message({
          message: '设置失败',
          type: 'warning'
        })
      }
    },
    //时间戳转年月日时分秒
    setTime (time) {
      var date = new Date(time * 1000)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      return Y + M + D + ' ' + h + m + s
    },
    selectWeb (type) {
      if (type) {
        this.runPoll()
      }
      this.tinInver = setInterval(async () => {
        if (!this.webSocket || this.webSocket.readyState != 1) {
          await this.runPoll(1)
        } else {
          // receivedMessage.value.send(JSON.stringify({ "c": "msg", "p": ["CloudStartHost", "upload", "CloudStart"], "f": "web", "data": { "action": "ping" } }))
        }
      }, 10000)
    },
    async runPoll (type) {
      if (!!this.webSocket && this.webSocket.readyState == 1) {
        this.webSocket.close()
      }

      // 在这里处理轮询结束后的操作
      let src = await this.pollPorts()

      // 创建 WebSocket 连接
      if (!src) {
        console.log('无法进入链接', src, this.webSocket)
        return
      }

      console.log('src', src)
      this.webSocket = new WebSocket(src)

      // 监听连接打开事件
      this.webSocket.onopen = async () => {

        this.webSocket.send(JSON.stringify({ c: "reg", p: ["web"] }))

      }

      this.webSocket.onmessage = (res) => {
        let data = JSON.parse(res.data).data
        if (data.action == 'updata_list_completed') {
          console.log('更新回复')
          if (data.code == '0x0' || data.code == '0xFF') {
            this.startApp('up')
          } else {
            this.$notify({
              title: '提示',
              message: '更新失败',
              duration: 0
            })
          }
        }
        if (data.reply == 'runapp') {
          if (data.code == '0x0') {
            this.start = false
            this.$notify({
              title: '提示',
              message: '启动成功',
              duration: 0
            })
          } else {
            this.start = false
            this.$notify({
              title: '提示',
              message: '启动失败',
              duration: 0
            })
          }
        }

      }
      //链接关闭
      this.webSocket.onclose = (res) => {
        console.log('链接关闭')
      }

      this.webSocket.onerror = (res) => {
        console.log('链接错误')
      }
    },
    fetchFromPorts (ports) {
      const promises = ports.map(port => {
        let myHeaders = new Headers()
        myHeaders.append('port', port)
        return fetch('http://127.0.0.1' + `:${port}/disk/get_disk_info?myPort=${port}`, { headers: myHeaders })
      })

      return Promise.race(promises)
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              this.drive = data.drive_letter
            })
            return response
          } else {
            return false
          }
        })
    },
    async pollPorts () {
      let startPort = 48688 // 起始端口
      let maxAttempts = 3// 最大尝试次数
      let list = []
      for (let port = startPort; port < startPort + maxAttempts; port++) {
        list.push(port)
      }
      let ms = await this.fetchFromPorts(list)
      console.log('msz', ms)
      if (ms) {
        let port = ms.url.split('=')[1]
        this.port = port
        // mySocket.port = 55555
        return 'ws://127.0.0.1' + `:${this.port}`
      } else {
        return ms
      }
    },
    //游戏更新
    uploadApp () {
      let obj = {
        action: "updata_list",
        payload: { path: '329390' }
      }
      return fetch(`http://127.0.0.1:${this.port}/disk/command`, {
        method: 'POST',
        body: JSON.stringify(obj)
      })
    },
    async versionApp () {
      console.log('检查更新')

      let res = await $.ajax({ url: 'http://127.0.0.1:' + this.port + '/disk/is_app_updated?appid=329390' })
      return res
    },
    async startApp (row, type) {
      console.log('启动游戏')
      this.selectItem = row
      if (type == 'up') {
        this.setLocalhostIds()
        this.start = true
        let ms = JSON.stringify({ "c": "msg", "p": ["cloudstart"], "data": { "action": "runapp", "appid": '329390', "path": '329390' + '', "flags": 0, "run": 'Palworld.exe', "cmd": '--host=192.168.0.233 --port=8211' } })
        this.webSocket.send(ms)
        return
      }
      let vser = await this.versionApp()
      let ks = vser.code
      if (!this.port) {
        this.setTab(-1)
        return
      }
      if (ks == '0xE00100D1') {
        this.$message({
          message: '未设置缓存磁盘，请到云启动官网，磁盘管理页面进行缓存磁盘设置',
          type: 'warning'
        })
      }
      if (ks == '0xE00100D2') {
        this.$message({
          message: '缓存磁盘正在插入，请稍后再重试启动游戏',
          type: 'warning'
        })
        return false
      }
      let lis = ['0xC0000034', '0xFF']
      if (lis.indexOf(ks) > -1) {
        this.setLocalhostIds()
        this.start = true
        let ms = JSON.stringify({ "c": "msg", "p": ["cloudstart"], "data": { "action": "runapp", "appid": '329390', "path": '329390' + '', "flags": 0, "run": 'Palworld.exe', "cmd": '--host=192.168.0.233 --port=8211' } })
        this.webSocket.send(ms)
        console.log('不需要更新')
        return true
      } else if (ks == '0x0') {
        this.uploadApp()
        this.uplaodLoading = true
        console.log('程序需要更新')
        return 2
      } else {
        //报错
        this.selectItem = null
        this.$notify({
          title: '提示',
          message: '启动失败，错误：' + ks,
          duration: 0
        })
        return false
      }
    },
    //保存登录过的账号
    setUser () {
      let user = JSON.parse(localStorage.getItem('userList')) || {}
      let obj = {
        user: this.account,
        pwd: this.password
      }
      user[this.winObj.id] = obj

      localStorage.setItem('userList', JSON.stringify(user))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="less">
/deep/.el-table__body tr.current-row>td.el-table__cell {
  background-color: #474747;
}

/deep/.el-table th.el-table__cell.is-leaf {
  border-color: #000;
}

/deep/.el-table::before {
  background-color: #000;
}

/deep/.el-table__header {
  width: 100% !important;
  table-layout: unset !important;
}

/deep/.el-table {
  background-color: #000;
}

/deep/.el-table td.el-table__cell {
  border-bottom-color: #5c5c5c;
}

/deep/.el-table tr th {
  background-color: #ffba00;
  color: #000;
}

/deep/.el-table .sort-caret.ascending {
  border-bottom-color: #000;
}

/deep/.el-table .sort-caret.descending {
  border-top-color: #000;
}

/deep/.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background-color: #474747;
}

/deep/.el-table__body-wrapper {
  background-color: #000;
}

/deep/.el-table tbody .el-table__cell {
  background-color: #000;
  color: rgba(255, 255, 255, .7);
}

/deep/.el-table__empty-block {
  background-color: #000;
  color: #fff;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 8px;
  /*滚动条宽度*/
  height: 8px;
  /*滚动条高度*/
}

/*定义滚动条轨道 内阴影+圆角*/
/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条的背景区域的内阴影*/
  /*滚动条的背景区域的圆角*/
  background-color: #000;
  /*滚动条的背景颜色*/
}

/*定义滑块 内阴影+圆角*/
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条的内阴影*/
  border-radius: 10px;
  /*滚动条的圆角*/
  background-color: #ffba00;
  /*滚动条的背景颜色*/
}

/deep/.el-table__body {
  width: 100% !important;
  background-color: #000;
}

/deep/.el-pagination {
  display: flex;
}

/deep/.el-pagination.is-background .btn-next,
/deep/.el-pagination.is-background .btn-prev,
/deep/.el-pagination.is-background .el-pager li {
  background-color: #666666;
  color: #fff;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ffba00;
  border-color: #ffba00;
  color: #000;
}

.inxdeBox {
  user-select: none !important;
  width: 12.8rem;
  height: 9.06rem;
  box-sizing: border-box;
  background-color: #000;


  .input_search {
    display: block;
    box-sizing: border-box;
    border: 0;
    outline: none;
    width: 100%;
    font-size: .20rem;
    background-color: #333333;
    border-color: #333333;
    height: .5rem;
    color: #fff;
    padding: .1rem;
    border: 1px solid #333333;

    &:focus {
      border: 1px solid #ffba00;
      background-color: #3d3d3d;

    }
  }

  .box_inof {
    width: 100%;
    height: 9.54rem;
    margin: 0 auto;

    .mu_tiles {
      display: flex;
      align-items: center;
      color: #fff;
      height: .48rem;
      background-color: #262626;
      font-size: .16rem;
      box-sizing: border-box;
      padding: 0 .3rem;

      .login_btn {
        margin-left: auto;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: 4px;
        padding: .1rem;
        font-size: 14px;

        .text_login {
          font-size: 12px;
          margin-left: 5px;
        }

        &:hover {
          color: #ffba00;
          cursor: pointer;
          background-color: #5c5c5c;
        }
      }
    }

    .head_box {
      width: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: .27rem;

      .log_icon {
        flex-shrink: 0;
        width: 1.91rem;
        height: .70rem;
        margin-right: .1rem;
      }

      .input_box {
        box-sizing: border-box;
        flex: 1;
        height: .5rem;
        background-color: #000;
        padding: .1rem 0 .1rem .1rem;
        display: flex;
        align-items: center;

        .el_btn {
          display: block;
          width: 2rem;
          flex-shrink: 0;
          height: .5rem;
          border-radius: 0;

          .i_box {
            display: flex;
            align-items: center;
            justify-content: center;

            .dow_icon {
              width: .27rem;
              height: .26rem;
            }

            .btn_font {
              font-size: .16rem;
              margin-left: .1rem;
            }
          }

        }
      }
    }

    .tab_list {
      display: flex;
      height: .48rem;
      font-size: .18rem;
      background-color: #000;

      .tab_item {
        font-size: .18rem;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.2rem;
        background-color: #333333;
        cursor: pointer;

        &.is_ck {
          color: #000;
          background-color: #ffba00;
        }
      }
    }

    .s_tabl {
      height: 5.04rem;
    }
  }

  .page_box {
    height: .72rem;
    background-color: #404040;
    display: flex;
    box-sizing: border-box;
    padding: 0 .15rem;
    align-items: center;
  }

  .item_msg_info {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: .2rem;
    height: 1.08rem;
    background-color: #333333;

    .tills {
      color: #fff;

      .t1_s {
        font-size: .18rem;
      }

      .t2_s {
        font-size: .14rem;
      }
    }

    .fgx {
      height: .68rem;
      width: 1px;
      background-color: #5c5c5c;
      margin: 0 .4rem;
    }

    .serve_box {
      flex: 1;
      text-align: left;
      color: #fff;

      .serve_name {
        font-size: .16rem;

      }

      .serve_desc {
        font-size: .12rem;
      }
    }

    .online_box {
      width: .8rem;
      color: #fff;
      padding-right: .4rem;

      .label {
        font-size: .14rem;
        text-align: center;
      }

      .num_size {
        font-size: .16rem;
        margin-top: .1rem;
      }
    }

    .start_box {
      width: 2rem;
      height: .68rem;
      font-size: .24rem;
      color: #fff;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      background-color: #0b80c3;

      &+.start_box {
        margin-left: .2rem;
      }

      &.kslz {
        box-sizing: border-box;
        border: 2px solid #ffba00;
        color: #ffba00;
        background-color: transparent;
      }
    }
  }
}
</style>
