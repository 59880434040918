<template>
  <el-dialog
             title="提示"
             :visible.sync="showModel"
             :close-on-click-modal="false"
             width="5rem"
             :before-close="close">
    <div class="box_ms">
      <div class="head_box">
        <div class="tils">分享给朋友</div>
        <i style="display: block;" class="el-icon-close clos_iso" @click="close"></i>
      </div>
      <div class="box_tabl">
        <div class="wx_tisl">微信扫描下方二维码分享</div>
        <div class="qr_box">
          <div class="im_qr" id="qrcode"></div>
        </div>

      </div>
      <div class="btn_box">
        <div class="link_String">
          {{ qrValue }}
        </div>
        <div class="link_copy" @click="copyText">
          <img src="../assets/link.png" class="img_icon">
          <div class="text" >复制URL</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
  name: 'serveList',
  props: {
    siteItem: {
      type: Object,
      default: () => {
        return {
          name: ''
        }
      }
    }
  },
  data () {
    return {
      qrValue: '',
      serveIcon: "https://pal.yunqidong.com/#/",
      showModel: true,
      password: '',
      account: '',
    }
  },
  mounted () {
    this.qrValue = this.serveIcon + '?search=' + this.siteItem.name
    this.$nextTick(() => {
      new QRCode(document.getElementById("qrcode"), { text: this.qrValue, height: 200, width: 200 })
    })
  },
  methods: {
    copyText () {
      console.log('进入复制')
      const textarea = document.createElement('textarea')
      textarea.value = this.qrValue
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand('copy')
        console.log('文本已复制到剪贴板')
        this.$message.success('链接已经复制到剪切板')
      } catch (e) {
        console.log('复制失败')
      }
      document.body.removeChild(textarea)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
<style scoped lang="less">
/deep/.el-dialog .el-dialog__header,
/deep/.el-dialog .el-dialog__body,
/deep/.el-dialog .el-dialog__footer {
  padding: 0;
}

/deep/.el-dialog .el-dialog__header {
  display: none;
}

/deep/.el-table tbody .el-table__cell {
  background-color: #333333 !important;
  color: rgba(255, 255, 255, .7);
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条的背景区域的内阴影*/
  /*滚动条的背景区域的圆角*/
  background-color: #333333 !important;
  /*滚动条的背景颜色*/
}

.box_ms {
  background-color: #333333;
  padding-bottom: .4rem;

  .head_box {
    background-color: #4d4d4d;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: .2rem;
    justify-content: space-between;
    height: .54rem;
    box-sizing: border-box;
    padding: 0 .2rem;

    .clos_iso {
      &:hover {
        cursor: pointer;
        color: #ffba00;
      }
    }
  }

  .box_tabl {
    width: 4.2rem;
    height: 3.2rem;
    background-color: #fff;
    margin: 0 auto;
    margin-top: .4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .wx_tisl {
      font-size: .18rem;
      color: #000;
    }

    .qr_box {
      width: 2rem;
      height: 2rem;
      margin-top: .1rem;

      .im_qr {
        width: 100%;
        height: 100%;
      }
    }
  }

  .btn_box {
    height: 1rem;
    width: 4.2rem;
    background-color: #000;
    margin: 0 auto;
    display: flex;

    .link_String {
      width: 3.2rem;
      color: rgba(255, 255, 255, .6);
      height: 1rem;
      font-size: .16rem;
      box-sizing: border-box;
      padding: .2rem;
      display: flex;
      align-items: center;
      text-align: left;
    }

    .link_copy {
      width: 1rem;
      background-color: #0b80c3;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;

      .img_icon {
        display: block;
        width: .34rem;
        height: .34rem;
      }

      .text {
        font-size: .16rem;
        margin-top: .1rem;
        color: #fff;
      }
    }
  }

}
</style>