import { render, staticRenderFns } from "./downPage.vue?vue&type=template&id=9830dae2&scoped=true"
import script from "./downPage.vue?vue&type=script&lang=js"
export * from "./downPage.vue?vue&type=script&lang=js"
import style0 from "./downPage.vue?vue&type=style&index=0&id=9830dae2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9830dae2",
  null
  
)

export default component.exports