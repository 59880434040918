<template>
  <el-dialog
             title="提示"
             :visible.sync="showModel"
             :close-on-click-modal="false"
             width="5rem"
             :before-close="close">
    <div class="box_ms">
      <div class="head_box">
        <div class="tils">设置语音快捷键</div>
        <i style="display: block;" class="el-icon-close clos_iso" @click="close"></i>
      </div>
      <input v-model="inputKey" readonly
             @focus="inputFocus = true"
             @blur="inputFocus = false"
             inputmode="none"
             ref="key_input"
             placeholder="请设置语音键"
             class="key_input" />
      <!-- <div class="key_input">{{ inputKey }}</div> -->
      <!-- <input v-model="inputKey" disabled
             @focus="inputFocus = true"
             @blur="inputFocus = false"
             ref="key_input"
             placeholder="请设置语音键"
             class="key_input" /> -->
      <div class="btn_box">
        <el-button class="btn_ks" type="primary" @click="submitKey">确定</el-button>
        <el-button class="btn_ks" type="primary" @click="close">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    defaultKey: {
      type: String,
      default: 'T'
    }
  },
  data () {
    return {
      showModel: true,
      inputFocus: true,
      inputKey: '',
      keyCodeMap: {
        9: 'Tab',
        13: 'Enter',
        16: 'Shift',
        17: 'Ctrl',
        18: 'Alt',
        27: 'Escape',
        32: 'Space',
        33: 'PageUp',
        34: 'PageDown',
        35: 'End',
        36: 'Home',
        48: '0',
        49: '1',
        50: '2',
        51: '3',
        52: '4',
        53: '5',
        54: '6',
        55: '7',
        56: '8',
        57: '9',
        65: 'A',
        66: 'B',
        67: 'C',
        68: 'D',
        69: 'E',
        70: 'F',
        71: 'G',
        72: 'H',
        73: 'I',
        74: 'J',
        75: 'K',
        76: 'L',
        77: 'M',
        78: 'N',
        79: 'O',
        80: 'P',
        81: 'Q',
        82: 'R',
        83: 'S',
        84: 'T',
        85: 'U',
        86: 'V',
        87: 'W',
        88: 'X',
        89: 'Y',
        90: 'Z',
        112: 'F1',
        113: 'F2',
        114: 'F3',
        115: 'F4',
        116: 'F5',
        117: 'F6',
        118: 'F7',
        119: 'F8',
        120: 'F9',
        121: 'F10',
        122: 'F11',
        123: 'F12',
        186: ';',
        187: '=',
        188: ',',
        189: '-',
        190: '.',
        191: '/',
        192: '`',
        219: '[',
        220: '\\',
        221: ']',
        222: '\'',
      }
    }
  },
  created () {

    let keys = []
    let that = this
    document.addEventListener('keydown', function (event) {
      event.preventDefault()
      console.log('event', event.keyCode)
      let key = that.keyCodeMap[event.keyCode] || ''
      if (((keys.length == 1 && keys[0].key != key) || keys.length == 0) && !!key) {
        if (keys.length == 1) {
          if (keys[0].keyCode > event.keyCode) {
            keys.unshift({ keyCode: event.keyCode, key: key })
          } else {
            keys.push({ keyCode: event.keyCode, key: key })
          }
        } else {
          keys.push({ keyCode: event.keyCode, key: key })
        }
      }

      if (keys.length == 2) {
        that.setKey(keys)
        console.log(`${keys[0]} 和 ${keys[1]} 被同时按下`)
      }
      if (keys.length == 1) {
        that.setKey(keys)
        console.log(`单个按键被按下${keys[0]}`)
      }
    })

    // document.addEventListener('mousedown', function (event) {
    //   event.preventDefault()
    //   // 在大多数浏览器中，鼠标侧键的 button 值为 2
    //   console.log(event.button)
    //   if (event.button === 2) {
    //     console.log('鼠标侧键被点击')
    //   }
    // })
    // document.addEventListener('auxclick', function (event) {
    //   event.preventDefault()
    //   console.log('鼠标键被点击', event.button)

    // })

    document.addEventListener('keyup', function (event) {
      event.preventDefault()
      let key = that.keyCodeMap[event.keyCode] || ''
      if (!key) {
        return
      }
      for (let i = 0; i < keys.length; i++) {
        if (keys[i].key == key) {
          keys.splice(i, 1)
        }
      }
    })
  },
  mounted () {
    this.$nextTick(() => {
      this.inputKey = this.defaultKey
      this.$refs.key_input.focus()
    })
  },
  beforeDestroy () {
    document.removeEventListener('keydown', function (event) {
      event.preventDefault()
    })
    document.removeEventListener('mousedown', function (event) {
      event.preventDefault()
    })
    document.removeEventListener('keyup', function (event) {
      event.preventDefault()
    })
  },
  methods: {
    submitKey () {
      this.$emit('submitKey', this.inputKey)
    },
    setKey (key) {
      console.log('this.inputKey', key)
      this.inputKey = key.map(item => item.key).join('+')
      this.$forceUpdate()
    },
    close () {
      this.$emit('close')
    },
    setFocus () {
      this.inputFocus = true
    },
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog .el-dialog__header,
/deep/.el-dialog .el-dialog__body,
/deep/.el-dialog .el-dialog__footer {
  padding: 0;
}

/deep/.el-dialog .el-dialog__header {
  display: none;
}

/deep/.el-table tbody .el-table__cell {
  background-color: #333333 !important;
  color: rgba(255, 255, 255, .7);
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条的背景区域的内阴影*/
  /*滚动条的背景区域的圆角*/
  background-color: #333333 !important;
  /*滚动条的背景颜色*/
}

.box_ms {
  width: 100%;
  background-color: #333333;

  .head_box {
    background-color: #4d4d4d;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: .2rem;
    justify-content: space-between;
    height: .54rem;
    box-sizing: border-box;
    padding: 0 .2rem;

    .clos_iso {
      &:hover {
        cursor: pointer;
        color: #ffba00;
      }
    }
  }

  .key_input {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 0;
    outline: none;
    width: 3rem;
    font-size: .16rem;
    background-color: #000;
    border-color: #000;
    height: .46rem;
    color: #ffba00;
    padding: .1rem;
    border: 1px solid #333333;
    margin: 0 auto;
    margin-top: .5rem;
    border-radius: .2rem;
    text-align: center;
    border: 1px solid #ffba00;
    background-color: #000;

    &:focus {
      border: 1px solid #ffba00;
      background-color: #000;

    }
  }

  .btn_box {
    margin-top: .2rem;
    display: flex;
    padding-bottom: .2rem;
    width: 3rem;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: .4rem;

    .btn_ks {
      width: 49%;
      display: block;
    }
  }
}
</style>