<template>
  <el-dialog
             title="提示"
             :visible.sync="showModel"
             :close-on-click-modal="false"
             width="5rem"
             :before-close="close">
    <div class="downPage">
      <div class="head_box">
        <div class="tils">启动中</div>
        <i style="display: block;" class="el-icon-close clos_iso" @click="close"></i>
      </div>
      <div class="in_box">
        <div class="head_tile">
          <!-- <div class="box_siz"></div>
          <div class="box_siz"></div>
          <div class="box_siz"></div> -->
          <i class="el-icon-loading"></i>
          <div class="mst_tips">游戏启动中</div>
          <!-- <div class="box_siz"></div>
          <div class="box_siz"></div>
          <div class="box_siz"></div> -->
        </div>
        <div class="hed_tex">
          如果游戏启动失败，请确认您的游戏是否完整
        </div>
        <!-- <div>
          <el-button class="btn_sk" type="primary">
            <div class="i_box">
              <img class="dow_icon" src="../assets/servericon.png">
              <div class="btn_font">下载私服服务端</div>
            </div>
          </el-button>
        </div> -->
        <!-- <div>
          <el-button class="btn_sk ks" type="primary">
            <div class="i_box">
              <img class="dow_icon" src="../assets/reload.png">
              <div class="btn_font" @click="restart">重新启动游戏</div>
            </div>
          </el-button>
        </div> -->
      </div>

    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "downPage",
  data () {
    return {
      showModel: true
    }
  },
  methods: {
    restart () {
      this.$emit('restart')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog .el-dialog__header,
/deep/.el-dialog .el-dialog__body,
/deep/.el-dialog .el-dialog__footer {
  padding: 0;
}

/deep/.el-dialog .el-dialog__header {
  display: none;
}

/deep/.el-table tbody .el-table__cell {
  background-color: #333333 !important;
  color: rgba(255, 255, 255, .7);
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条的背景区域的内阴影*/
  /*滚动条的背景区域的圆角*/
  background-color: #333333 !important;
  /*滚动条的背景颜色*/
}

.downPage {
  width: 100%;
  height: 5rem;
  background-color: #333333;

  .head_box {
    background-color: #4d4d4d;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: .2rem;
    justify-content: space-between;
    height: .54rem;
    box-sizing: border-box;
    padding: 0 .2rem;

    .clos_iso {
      &:hover {
        cursor: pointer;
        color: #ffba00;
      }
    }
  }

  .in_box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90%;

    .dow_icon {
      width: .36rem;
      height: .34rem;

    }

    .head_tile {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .48rem;
      color: #e5a700;

      .mst_tips {
        margin: 0 .2rem;
      }

      .box_siz {
        width: .08rem;
        height: .08rem;
        border-radius: 50%;
        background-color: #e5a700;
        margin: 0 .06rem;
      }
    }

    .hed_tex {
      font-size: .18rem;
      color: #fff;
      margin-top: .3rem;
      margin-bottom: .3rem;
    }

    .btn_sk {
      width: 3.2rem;
      height: .66rem;
      border: 0;
      display: block;
      margin-top: .2rem;

      .i_box {
        display: flex;
        align-items: center;
        justify-content: center;

        .dow_icon {
          width: .36rem;
          height: .34rem;
        }

        .btn_font {
          font-size: .2rem;
          margin-left: .1rem;
        }
      }

      &.ks {
        background-color: #000;
        border: .02rem solid #ffba00;
        color: #ffba00;
      }
    }
  }


}
</style>