import Vue from 'vue'
import Router from 'vue-router'
import index from './view/index'
import winIndex from './view/pcIndex'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: index
    },
    {
      path: '/win',
      name: 'winIndex',
      component: winIndex
    }
    // 在这里添加更多的路由
  ]
})