<template>
  <el-dialog
             title="提示"
             :visible.sync="showModel"
             :close-on-click-modal="false"
             width="4rem"
             :before-close="close">
    <form @submit="startApp" tabindex="-1" class="box_ms">
      <div class="head_box">
        <div class="tils">{{ loginType ? '盒子登录' : '账号登录' }}</div>
        <i style="display: block;" class="el-icon-close clos_iso" @click="close"></i>
      </div>
      <template v-if="!wxLogin || version == 0">
        <div class="box_tabl">


          <input @keydown.tab.prevent ref="user_ack" class="input_search" tabindex="1" maxlength="17"
                 placeholder="请输入账号"
                 v-model="account">
          <input type="password" @keydown.tab.prevent ref="user_pwd" class="input_search" tabindex="2" maxlength="11"
                 placeholder="请输入密码"
                 v-model="password">
          <!-- <input type="password" @keydown.tab.prevent ref="user_pwds" class="input_search" tabindex="-1" maxlength="11"
               placeholder="请输入密码"
               v-model="password"> -->
          <div class="tips">如果账号不存在，则会自动注册，用于个人存档</div>
          <div class="btn_box">
            <el-button class="btn_ks" type="primary" @click="startApp" tabindex="-1">进入游戏</el-button>
            <el-button class="btn_ks" type="primary" @click="close" tabindex="-1">取消</el-button>
            <input type="submit" style="display: none" />
          </div>
          <div class="box_Qh" v-if="version >= 32">
            <template v-if="!loginState">
              <div class="qh" @click="switchLogin">
                <i class="el-icon-sort" style="margin-right: 5px;"></i>
                微信扫码登录
              </div>
            </template>

            <template v-else>
              <div class="qh" @click="(e) => { this.startApp(e, 1) }">
                <i class="el-icon-s-help" style="margin-right: 5px;"></i>
                进入游戏
              </div>
            </template>
          </div>
        </div>

      </template>

      <template v-else>
        <div class="wx_box">
          <!-- <template v-if="!loginState"> -->
          <div class="tips">请用微信扫描登录</div>
          <img class="qr_img" :src="qrSrc">
          <div class="tips" style="color: #ffba00;">如果账号不存在，则会自动注册，用于个人存档</div>
          <div class="qh" @click="switchLogin" v-if="!loginType">
            <i class="el-icon-sort"></i>
            账号密码登录
          </div>
          <!-- </template> -->

          <!-- <template v-else>
            <div class="btn_itme_ac" @click="switchLogin">
              <i class="el-icon-sort"></i>
              <div class="text">账号密码登录</div>
            </div>
            <div class="btn_itme_ac join" @click="(e) => { this.startApp(e, 1) }">
              <i class="el-icon-s-help"></i>
              <div class="text">进入游戏</div>
            </div>
          </template> -->

        </div>
      </template>
    </form>
  </el-dialog>
</template>

<script>
import http from '../request'
export default {
  name: 'serveList',
  props: {
    version: {
      type: Number,
      default: 0
    },
    //判断是否是盒子登录
    loginType: {
      type: Boolean,
      default: false
    },
    winObj: {
      type: Object,
      default: () => {
        return {

        }
      }
    }
  },
  data () {
    return {
      wxLogin: true,//是否是微信登录
      loginState: false,//登录状态
      qrSrc: require('../assets/code.png'),
      showModel: true,
      password: '',
      account: '',
      inputIndex: 0,
      searchUrl: '',//查询二维码是否扫描过验证
      wxOpenId: '',//微信登录后保存的openid
      tiemInvt: null,//定时器
    }
  },
  mounted () {
    //获取缓存的账号
    let typeL = localStorage.getItem('loginType') || 0
    this.wxLogin = typeL == 0

    this.$nextTick(() => {
      let openId = localStorage.getItem('wxOpenId') || ''
      this.wxOpenId = openId
      if (!!openId) {
        this.loginState = true
        this.startApp(undefined, 1)
      } else {
        if (this.wxLogin && this.version >= 32) {
          this.loginState = false
          this.getQrSrc()
        } else {
          clearInterval(this.tiemInvt)
          this.$refs.user_ack.focus()
          let user = JSON.parse(localStorage.getItem('userList')) || {}
          let item = user[this.winObj.id]
          if (!!item) {
            this.account = item.user
            this.password = item.pwd
          }
        }
      }
      document.addEventListener('keydown', this.tabSet)
    })
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.tabSet)
  },
  methods: {
    switchLogin () {
      this.wxLogin = !this.wxLogin
      if (this.wxLogin) {
        this.getQrSrc()
      } else {
        clearInterval(this.tiemInvt)
        this.$nextTick(() => {
          this.$refs.user_ack.focus()
          let user = JSON.parse(localStorage.getItem('userList')) || {}
          let item = user[this.winObj.id]
          if (!!item) {
            this.account = item.user
            this.password = item.pwd
          }
        })
      }
    },
    //获取二维码链接
    getQrSrc () {
      http({
        url: 'https://api.guanliyuangong.com/weixin/auth?type=qr'
      }).then(res => {
        if (res.code == 0) {
          this.qrSrc = res.data.qr_url
          this.searchUrl = res.data.chk_url
          this.searchLink()
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }

      })
    },
    searchLink () {
      this.tiemInvt = setInterval(() => {
        http({
          url: this.searchUrl
        }).then(res => {
          if (res.code == 0) {
            clearInterval(this.tiemInvt)
            this.wxOpenId = res.data.openid
            localStorage.setItem('wxOpenId', this.wxOpenId)
            this.$emit('login')
            if (!this.loginType) {
              console.log('微信登录成功，进入游戏')
              this.startApp(undefined, 1)
            } else {
              this.$message({
                message: '微信登录成功',
                type: 'success'
              })
              this.close()
            }
          }
        })
      }, 1800)
    },
    tabSet (e) {
      if (e.keyCode == 9) {
        console.log('tab', e)
        e.preventDefault()
        if (this.inputIndex == 0) {
          this.$refs.user_pwd.focus()
          this.inputIndex = 1
        } else {
          this.$refs.user_ack.focus()
          this.inputIndex = 0
        }
      }
    },
    startApp (e, type = 0) {
      if (!!e) {
        e.preventDefault()
      }

      if ((this.password.length < 3 || this.account.length < 3) && type == 0) {
        this.$message({
          message: '账号或密码不能低于3位',
          type: 'warning'
        })
        return
      }
      this.setLocalhostIds(this.winObj.id)

      let dataObj = {
        'ip': this.winObj.ip + '',
        'port': this.winObj.port + '',
        'host': this.winObj.host + '',
        'id': this.winObj.id + '',
        'voiceport': this.winObj.voiceport + '',
        'apiport': this.winObj.apiport + '',
        'hash': this.winObj.hash + '',
        'pwd': this.password + '',
        'user': this.account + ''
      }

      if (type == 1) {
        dataObj['pwd'] = this.wxOpenId + ''
        dataObj['user'] = this.wxOpenId + ''
        localStorage.setItem('loginType', 0)
      } else {
        localStorage.setItem('loginType', 1)
      }


      let gameStae = window.serverJS(JSON.stringify({
        'c': 'call', 'a': 'login', 'data': dataObj
      }))

      if (gameStae == 0) {
        this.$message({
          message: '没有寻找到帕鲁客户端，请先下载安装',
          type: 'warning'
        })
        return
      }

      if (gameStae == 1) {
        this.$message({
          message: '正在寻找帕鲁客户端,请稍后...',
          type: 'warning'
        })
        return
      }

      if (gameStae == 2) {
        this.$message({
          message: '请先关闭正在运行的帕鲁客户端',
          type: 'warning'
        })
        return
      }
      this.$emit('start', { pwd: this.password, user: this.account })
    },
    close () {
      this.$emit('close')
      clearInterval(this.tiemInvt)
    }, setLocalhostIds (id) {
      console.log('设置本地缓存')
      let localis = JSON.parse(localStorage.getItem('localId')) || []
      if (localis.indexOf(id) == -1) {
        localis.push(id)
        localStorage.setItem('localId', JSON.stringify(localis))
      }
    }

  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog .el-dialog__header,
/deep/.el-dialog .el-dialog__body,
/deep/.el-dialog .el-dialog__footer {
  padding: 0;
}

/deep/.el-dialog .el-dialog__header {
  display: none;
}

/deep/.el-table tbody .el-table__cell {
  background-color: #333333 !important;
  color: rgba(255, 255, 255, .7);
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条的背景区域的内阴影*/
  /*滚动条的背景区域的圆角*/
  background-color: #333333 !important;
  /*滚动条的背景颜色*/
}

.box_ms {
  background-color: #333333;

  .head_box {
    background-color: #4d4d4d;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: .2rem;
    justify-content: space-between;
    height: .54rem;
    box-sizing: border-box;
    padding: 0 .2rem;

    .clos_iso {
      &:hover {
        cursor: pointer;
        color: #ffba00;
      }
    }
  }

  .wx_box {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;

    .tips {
      font-size: 14px;
      color: #fff;
    }

    .qh {
      color: #fff;
      margin-top: 25px;
      padding-bottom: 30px;

      .el-icon-sort {
        transform: rotate(90deg);
      }

      &:hover {
        color: #ffba00;
        cursor: pointer;
      }
    }

    .qr_img {
      padding: 25px 0;
      width: 220px;
      height: 220px;
    }

    .btn_itme_ac {
      width: 100%;
      display: flex;
      background-color: #409EFF;
      height: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.join {
        background-color: #ffba00;

        &:hover {
          cursor: pointer;
          background-color: lighten(#ffba00, 2%);
        }
      }

      .el-icon-sort,
      .el-icon-s-help {
        color: #fff;
        font-size: 32px;
      }

      .text {
        color: #fff;
        font-size: 25px;
        margin-left: 10px;
      }

      &+.btn_itme_ac {
        margin-top: 10px;
      }

      &:hover {
        cursor: pointer;
        background-color: lighten(#409EFF, 2%);
      }
    }
  }

  .box_tabl {
    box-sizing: border-box;
    padding: .2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;

    .tips {
      color: #ffba00;
      margin-top: .1rem;
    }



    .input_search {
      display: block;
      box-sizing: border-box;
      border: 0;
      outline: none;
      width: 3rem;
      font-size: .16rem;
      background-color: #000;
      border-color: #000;
      height: .46rem;
      color: #fff;
      padding: .1rem;
      border: 1px solid #333333;

      &+.input_search {
        margin-top: .1rem;
      }

      &:focus {
        border: 1px solid #ffba00;
        background-color: #000;

      }
    }

    .box_Qh {
      margin-top: 30px;

      .qh {
        width: 100%;
        color: #fff;
        display: flex;
        justify-content: center;

        .el-icon-sort,
        .el-icon-s-help {
          transform: rotate(90deg);
        }

        &:hover {
          color: #ffba00;
          cursor: pointer;
        }
      }
    }


    .btn_box {
      margin-top: .2rem;
      display: flex;
      width: 3rem;
      align-items: center;
      justify-content: center;

      .btn_ks {
        width: 49%;
        display: block;
      }
    }
  }


}
</style>