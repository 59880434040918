<template>
  <div class="inxdeBox">
    <div class="box_inof">
      <div class="head_box">
        <img class="log_icon" src="../assets/logo.png" />
        <div class="input_box">
          <form @submit="getData" style="width: 100%;">
            <input maxlength="20" placeholder="请输入私服名字" v-model="searchServe" class="input_search" />
          </form>
          <el-button class="el_btn_ks" @click="getData" type="primary">
            检索
          </el-button>
          <el-button class="el_btn" @click="setTab(-1)" type="primary">
            <div class="i_box">
              <img class="dow_icon" src="../assets/downicon.png">
              <div class="btn_font">微端下载</div>
            </div>
          </el-button>
        </div>
      </div>
      <div class="tab_list">
        <div class="tab_item" @click="setTab(item.key)" :class="{ 'is_ck': item.key == tabIndex && !start }"
             v-for="(item, index) in tabList" :key="item.key">
          {{ item.label }}</div>
      </div>
      <template v-if="tabIndex != -1 && !start">
        <div class="s_tabl"
             element-loading-text="加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"
             v-loading="loading">
          <el-table :data="dataList" height="100%" highlight-current-row @sort-change="filterList">
            <el-table-column label="服务器名称" prop="name" align="center">
              <template slot-scope="scope">
                <div style="color: #ffba00;">{{ scope.row.name }}</div>
              </template>
            </el-table-column>
            <el-table-column label="服务器描述" prop="des" align="center">
              <template slot-scope="scope">
                <div style="text-align: left;" v-html="scope.row.des.replace(/\r?\n/g, '<br>')"></div>
              </template>
            </el-table-column>
            <el-table-column label="当前在线" prop="person" align="center" sortable="custom"></el-table-column>
            <el-table-column label="版本" prop="ver" align="center" sortable="custom"></el-table-column>
            <el-table-column label="线路" prop="network" align="center" sortable="custom"></el-table-column>
            <el-table-column label="开服时间" prop="start_time" align="center" sortable="custom">
              <!-- <template slot-scope="scope">
                <div>{{ setTime(scope.row.start_time) }}</div>
              </template> -->
            </el-table-column>
            <el-table-column label="详情" align="center">
              <template slot-scope="scope">
                <el-button type="primary" style="border-radius: 0;" @click="startApp(scope.row, 'one')">进入游戏</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="page_box">
          <el-pagination
                         @current-change="nextPage"
                         :current-page="page"
                         :page-size="size"
                         background
                         layout="prev, pager, next"
                         :total="count">
          </el-pagination>
        </div>
      </template>
      <downPage v-if="tabIndex == -1 && !start"></downPage>
      <template v-if="start">
        <startAppMode @restart="restartApp"></startAppMode>
      </template>
    </div>

    <!-- <serveList></serveList> -->
    <userlogin v-if="showLoading"></userlogin>
    <upload v-if="uplaodLoading"></upload>
  </div>
</template>

<script>
import http from '../request'
import serveList from '../components/serveList'
import downPage from '../components/downPage'
import startAppMode from '../components/startApp'
import userlogin from '../components/userlogin'
import upload from '../components/upload'
export default {
  name: 'HelloWorld',
  components: {
    serveList, downPage, startAppMode, userlogin, upload
  },
  data () {
    return {
      webSocket: null,
      webState: false,//websocket未连接
      uplaodLoading: false,
      tinInver: null,
      drive: '',
      loading: false,
      showLoading: false,
      searchServe: '',
      tabIndex: 0,
      port: null,
      localist: '',//本地缓存的ids列表
      start: false,
      count: 0,
      size: 10,
      page: 1,
      sortType: 'start_time',
      sort: 'desc',
      dataList: [],
      version: {
        'v0.1.3.0': '330031',
        'v0.1.2.0': '329390',
        'v0.1.4.0': '330038'
      },
      tabList: [
        { label: '网吧服务器', key: '0' },
        { label: '私有服务器', key: '1' },
        { label: '玩过的服务器', key: '2' },
        { label: '玩法教程', key: '-2' },
      ],

    }
  },
  created () {



  },
  mounted () {

    this.selectWeb(1)
    // console.log(this.$route)
    this.searchServe = this.$route.query.search || ''
    this.getList()
    $('#move').hide()

  },
  methods: {
    restartApp () {
      this.startApp(this.selectItem)
    },
    nextPage (val) {
      if (this.tabIndex != 2) {
        this.page = val
        this.localist = ''
        this.getList()
      } else {
        console.log('玩过的服务器 本地记录')
        this.getLocalData()
      }
    },
    getData (e) {
      e.preventDefault()
      if (this.tabIndex != 2) {
        this.page = 1
        this.localist = ''
        this.getList()
      } else {
        console.log('玩过的服务器 本地记录')
        this.getLocalData()
      }
    },
    filterList (opt) {
      this.sortType = opt.prop
      this.sort = opt.order == 'ascending' ? 'asc' : 'desc'
      this.page = 1
      if (this.tabIndex != 2) {
        this.localist = ''
        this.getList()
      } else {
        this.getLocalData()
      }
    },
    getLocalData () {
      this.localist = (JSON.parse(localStorage.getItem('localId')) || []).join(',')
      if (this.localist == '') {
        this.dataList = []
        return
      }
      this.getList()
    },
    getList () {
      if (this.loading || this.start) {
        return
      }
      this.loading = true
      http({
        url: '/servers/get-servers',
        data: {
          page: this.page,
          name: this.searchServe,
          size: this.size,
          type: this.tabIndex,
          sort_type: this.sortType,
          ids: this.localist,
          sort: this.sort
        }
      }
      ).then(res => {
        this.loading = false
        if (res.data.length == 0 && !!this.searchServe) {
          this.$message({
            message: '当前关键词无相关结果',
            type: 'warning'
          })
          return
        }
        this.dataList = res.data
        this.count = res.ext.total
      })
    },
    setTab (key) {
      if (key == -2) {
        window.open('https://pal.yunqidong.com/index/help')
        return
      }
      if (key == this.tabIndex || this.start) {
        return
      }
      this.searchServe = ''
      this.loading = false
      this.tabIndex = key
      this.start = false
      this.page = 1
      if (this.tabIndex != 2) {
        this.localist = ''
        this.getList()
      } else {
        this.getLocalData()
      }
    },

    //时间戳转年月日时分秒
    setTime (time) {
      var date = new Date(time * 1000)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      return Y + M + D + ' ' + h + m + s
    },
    selectWeb (type) {
      if (type) {
        this.runPoll()
      }
      this.tinInver = setInterval(async () => {
        if (!this.webSocket || this.webSocket.readyState != 1) {
          await this.runPoll(1)
        } else {
          // receivedMessage.value.send(JSON.stringify({ "c": "msg", "p": ["CloudStartHost", "upload", "CloudStart"], "f": "web", "data": { "action": "ping" } }))
        }
      }, 10000)
    },
    async runPoll (type) {
      if (!!this.webSocket && this.webSocket.readyState == 1) {
        this.webSocket.close()
      }

      // 在这里处理轮询结束后的操作
      let src = await this.pollPorts()

      // 创建 WebSocket 连接
      if (!src) {
        console.log('无法进入链接', src, this.webSocket)
        return
      }

      console.log('src', src)
      this.webSocket = new WebSocket(src)

      // 监听连接打开事件
      this.webSocket.onopen = async () => {

        this.webSocket.send(JSON.stringify({ c: "reg", p: ["web"] }))
        this.webState = true

      }

      this.webSocket.onmessage = (res) => {
        let data = JSON.parse(res.data).data
        if (data.action == 'updata_list_completed') {
          console.log('更新回复')
          if (data.code == '0x0' || data.code == '0xFF') {
            this.uplaodLoading = false
            this.startApp(this.selectItem, 'up')
          } else {
            this.$notify({
              title: '提示',
              message: '更新失败',
              duration: 0
            })
          }
        }
        if (data.reply == 'runapp') {
          if (data.code == '0x0') {
            this.start = false
            this.$notify({
              title: '提示',
              message: '启动成功',
              duration: 0
            })
          } else {
            this.start = false
            this.$notify({
              title: '提示',
              message: '启动失败',
              duration: 0
            })
          }
        }

      }
      //链接关闭
      this.webSocket.onclose = (res) => {
        console.log('链接关闭')
        this.webState = false
      }

      this.webSocket.onerror = (res) => {
        console.log('链接错误')
      }
    },
    fetchFromPorts (ports) {
      const promises = ports.map(port => {
        let myHeaders = new Headers()
        myHeaders.append('port', port)
        return fetch('http://127.0.0.1' + `:${port}/disk/get_disk_info?myPort=${port}`, { headers: myHeaders })
      })

      return Promise.race(promises)
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              this.drive = data.drive_letter
            })
            return response
          } else {
            return false
          }
        })
    },
    async pollPorts () {
      let startPort = 48688 // 起始端口
      let maxAttempts = 6// 最大尝试次数
      let list = []
      for (let port = startPort; port < startPort + maxAttempts; port++) {
        list.push(port)
      }
      let ms = await this.fetchFromPorts(list)
      console.log('msz', ms)
      if (ms) {
        let port = ms.url.split('=')[1]
        this.port = port
        // mySocket.port = 55555
        return 'ws://127.0.0.1' + `:${this.port}`
      } else {
        return ms
      }
    },
    //游戏更新
    uploadApp () {
      let obj = {
        action: "updata_list",
        payload: { path: this.version[this.selectItem.ver] || '330038' }
      }
      return fetch(`http://127.0.0.1:${this.port}/disk/command`, {
        method: 'POST',
        body: JSON.stringify(obj)
      })
    },
    async versionApp () {
      console.log('检查更新')

      let res = await $.ajax({ url: 'http://127.0.0.1:' + this.port + '/disk/is_app_updated?appid=' + `${this.version[this.selectItem.ver] || '330038'}` })
      return res
    },
    async startApp (row, type) {

      if (!this.webState) {
        this.setTab(-1)
      }
      // console.log('启动游戏1',this.selectItem,row)
      this.selectItem = row
      // this.start=true
      // console.log('启动游戏')
      console.log('version', this.version[this.selectItem.ver])
      if (type == 'up') {
        this.setLocalhostIds()
        this.start = true
        let ms = JSON.stringify({
          "c": "msg", "p": ["cloudstart"], "data": {
            "action": "runapp",
            "appid": `${this.version[this.selectItem.ver] || '330038'}` + '',
            "path": `${this.version[this.selectItem.ver] || '330038'}` + '',
            "flags": 0,
            "run": 'PalWorldLG.exe',
            "cmd": '--ip=' + (this.selectItem.ip || '') +
              ' --host=' + (this.selectItem.addr || '') +
              ' --port=' + (this.selectItem.addr_port || '') +
              ' --id=' + (this.selectItem.id || '') +
              ' --voiceport=' + (this.selectItem.voice_port || '') +
              ' --apiport=' + (this.selectItem.api_port || '') +
              ' --hash=' + (this.selectItem.hash || '')
          }
        })
        this.webSocket.send(ms)
        return
      }
      let vser = await this.versionApp()
      let ks = vser.code
      if (!this.port) {
        this.setTab(-1)
        return
      }
      if (ks == '0xE00100D1') {
        this.$message({
          message: '未设置缓存磁盘，请到云启动官网，磁盘管理页面进行缓存磁盘设置',
          type: 'warning'
        })
      }
      if (ks == '0xE00100D2') {
        this.$message({
          message: '缓存磁盘正在插入，请稍后再重试启动游戏',
          type: 'warning'
        })
        return false
      }
      let lis = ['0xC0000034', '0xFF']
      if (lis.indexOf(ks) > -1) {
        this.setLocalhostIds()
        this.start = true
        let ms = JSON.stringify({
          "c": "msg", "p": ["cloudstart"], "data": {
            "action": "runapp",
            "appid": `${this.version[this.selectItem.ver] || '330038'}` + '',
            "path": `${this.version[this.selectItem.ver] || '330038'}` + '',
            "flags": 0,
            "run": 'PalWorldLG.exe',
            "cmd": '--ip=' + (this.selectItem.ip || '') +
              ' --host=' + (this.selectItem.addr || '') +
              ' --port=' + (this.selectItem.addr_port || '') +
              ' --id=' + (this.selectItem.id || '') +
              ' --voiceport=' + (this.selectItem.voice_port || '') +
              ' --apiport=' + (this.selectItem.api_port || '') +
              ' --hash=' + (this.selectItem.hash || '')
          }
        })
        this.webSocket.send(ms)
        console.log('不需要更新')
        return true
      } else if (ks == '0x0') {
        this.uploadApp()
        this.uplaodLoading = true
        console.log('程序需要更新')
        return 2
      } else {
        //报错
        this.selectItem = null
        this.$notify({
          title: '提示',
          message: '启动失败，错误：' + ks,
          duration: 0
        })
        return false
      }
    },
    setLocalhostIds () {
      console.log('设置本地缓存')
      let localis = JSON.parse(localStorage.getItem('localId')) || []
      if (localis.indexOf(this.selectItem.id) == -1) {
        localis.push(this.selectItem.id)
        localStorage.setItem('localId', JSON.stringify(localis))
      }
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/.el-table__body tr.current-row>td.el-table__cell {
  background-color: #474747;
}

/deep/.el-table th.el-table__cell.is-leaf {
  border-color: #000;
}

/deep/.el-table::before {
  background-color: #000;
}

/deep/.el-table td.el-table__cell {
  border-bottom-color: #5c5c5c;
}

/deep/.el-table tr th {
  background-color: #ffba00;
  color: #000;
}

/deep/.el-table .sort-caret.ascending {
  border-bottom-color: #000;
}

/deep/.el-table .sort-caret.descending {
  border-top-color: #000;
}

/deep/.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background-color: #474747;
}

/deep/.el-table__body-wrapper {
  background-color: #000;
}

/deep/.el-table tbody .el-table__cell {
  background-color: #000;
  color: rgba(255, 255, 255, .7);
}

/deep/.el-table__empty-block {
  background-color: #000;
  color: #fff;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 8px;
  /*滚动条宽度*/
  height: 8px;
  /*滚动条高度*/
}

/*定义滚动条轨道 内阴影+圆角*/
/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条的背景区域的内阴影*/
  /*滚动条的背景区域的圆角*/
  background-color: #000;
  /*滚动条的背景颜色*/
}

/*定义滑块 内阴影+圆角*/
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条的内阴影*/
  border-radius: 10px;
  /*滚动条的圆角*/
  background-color: #ffba00;
  /*滚动条的背景颜色*/
}

/deep/.el-table__body {
  width: 100% !important;
  background-color: #000;
}

/deep/.el-pagination {
  display: flex;
}

/deep/.el-pagination.is-background .btn-next,
/deep/.el-pagination.is-background .btn-prev,
/deep/.el-pagination.is-background .el-pager li {
  background-color: #666666;
  color: #fff;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ffba00;
  border-color: #ffba00;
  color: #000;
}

.inxdeBox {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background: url('../assets/bg.jpg') no-repeat;
  padding-top: 5vh;
  background-size: 100% 100%;

  .input_search {
    display: block;
    box-sizing: border-box;
    border: 0;
    outline: none;
    width: 100%;
    font-size: .20rem;
    background-color: #333333;
    border-color: #333333;
    height: .46rem;
    color: #fff;
    padding: .1rem;
    border: 1px solid #333333;

    &:focus {
      border: 1px solid #ffba00;
      background-color: #3d3d3d;

    }
  }

  .box_inof {
    width: 14.4rem;
    height: 85vh;

    margin: 0 auto;

    .head_box {
      width: 100%;
      display: flex;
      align-items: center;

      .log_icon {
        flex-shrink: 0;
        width: 2.7rem;
        height: 1.1rem;
        margin-right: .1rem;
      }

      .input_box {
        box-sizing: border-box;
        flex: 1;
        height: .66rem;
        background-color: #000;
        padding: .1rem 0 .1rem .1rem;
        display: flex;
        align-items: center;

        .el_btn {
          display: block;
          width: 2rem;
          height: .66rem;
          margin-left: .1rem;
          border-radius: 0;

          .i_box {
            display: flex;
            align-items: center;
            justify-content: center;

            .dow_icon {
              width: .36rem;
              height: .34rem;
            }

            .btn_font {
              font-size: .2rem;
              margin-left: .1rem;
            }
          }

        }

        .el_btn_ks {
          display: block;
          width: 2rem;
          height: .46rem;
          border-radius: 0;
          font-size: .2rem;
          font-family: Inter, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, \5fae\8f6f\96c5\9ed1, Arial, sans-serif;

          .i_box {
            display: flex;
            align-items: center;
            justify-content: center;

            .dow_icon {
              width: .36rem;
              height: .34rem;
            }

            .btn_font {
              font-size: .2rem;
              margin-left: .1rem;
            }
          }

        }
      }
    }

    .tab_list {
      display: flex;
      height: .6rem;
      margin-top: .1rem;
      font-size: .2rem;
      background-color: #000;

      .tab_item {
        font-size: .2rem;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.4rem;
        background-color: #333333;
        cursor: pointer;

        &.is_ck {
          color: #000;
          background-color: #ffba00;
        }
      }
    }

    .s_tabl {
      height: calc(100% - 2.42rem);
    }
  }

  .page_box {
    height: .72rem;
    background-color: #404040;
    display: flex;
    box-sizing: border-box;
    padding: 0 .15rem;
    align-items: center;
  }
}
</style>
