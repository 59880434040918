
const service = (opt) => {
  return new Promise((resolve, reject) => {
    console.log('url', opt.url)
    let url = ''
    if (opt.url.indexOf('http') == -1) {
      url = 'https://palapi.yunqidong.com' + opt.url
    } else {
      url = opt.url
    }

    try {
      $.ajax({
        url: url,
        type: opt.type || 'get',
        data: opt.data || {},
        success: (res) => {
          console.log('res', res)
          resolve(res)
        }
        // error: (err) => {
        //   reject(err)
        // },
        // fail: (err) => {
        //   reject(err)
        // }
      })
    } catch (e) {
      console.log('e', res)
      reject(e)
    }
  })
}
export default service
