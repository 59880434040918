<template>
  <div
       class="upload"
       element-loading-text="游戏更新中，请稍后"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)"
       v-loading="true">

  </div>
</template>
<script>
export default {
  name: 'serveList',
  data () {
    return {
    }
  },
  methods: {
    close () {

    }
  }
}
</script>
<style scoped lang="less">
/deep/.el-loading-text{
  font-size: .34rem;
}

.upload {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
</style>