<template>
  <div class="left_menu" :class="{ 'open': openTab }">
    <div class="in_box">
      <div class="head_tile">
        <div class="tils">工具箱</div>
        <!-- <div class="wisl">按ctrt+F12呼出</div> -->
      </div>
      <div class="setting_box">
        <div class="ga_num_box">
          <div class="tils">伽马值设置</div>
          <input class="input_s" :max="max" :min="min" @change="setGama" type="range" v-model="gama" />
          <div class="num_box">
            <div class="min">{{ min }}</div>
            <div class="max">{{ max }}</div>
          </div>
          <el-button type="primary" class="auto_btn" @click="defaultGama">恢复亮度</el-button>
        </div>
        <div class="voice_box">
          <div class="bn_itme" @click="openWin">
            <img class="icn_jt" src="../assets/jt_mr.png">
            <div class="tis" style="margin-left: auto;">游戏攻略</div>
            <img class="bt_icon" src="../assets/mr1.png">
          </div>
          <div class="bn_itme" @click="btnVoice">
            <img class="icn_img" src="../assets/yy_icon.png">
            <div class="tis">实时语音</div>
            <img class="msk" v-show="!voiceState" src="../assets/off.png">
            <img class="msk" v-show="voiceState" src="../assets/on.png">
          </div>
          <div class="bn_itme" @click="setFree">
            <img class="icn_img" src="../assets/xz_icon.png">
            <div class="tis">自由麦</div>
            <img class="msk" v-show="!freeState" src="../assets/off.png">
            <img class="msk" v-show="freeState" src="../assets/on.png">
          </div>
          <div class="bn_itme" @click="setKeys" v-show="!freeState">
            <img class="icn_img" src="../assets/xz_icon.png">
            <div class="tis">按键说话</div>
            <div class="btn_key">{{ voiceKey }}</div>
          </div>

        </div>
      </div>
      <div class="btn_link" @click="openTabAs">
        <i v-if="!openTab" class="el-icon-arrow-right inc_si"></i>
        <i v-else class="el-icon-arrow-left inc_si"></i>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    voiceKey: {
      type: String,
      default: 'T'
    },
    gameIng: {
      type: Boolean,
      default: false
    },
    freeState: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      voiceState: false,
      openTab: false,
      gama: 128,
      min: 0,
      max: 255,
      key: 'R'
    }
  },
  mounted () {
    if (!!window.serverJS) {
      this.voiceState = window.serverJS(JSON.stringify({ c: 'conf', a: 'voicestatus' }))
      this.gama = window.serverJS(JSON.stringify({ c: 'conf', a: 'light' }))
      console.log('voiceState', this.voiceState)
    }
  },
  methods: {
    startShow () {
      this.openTab = true
    },
    openTabAs () {
      this.openTab = !this.openTab
    },
    setGama () {
      window.serverJS(JSON.stringify({ c: 'conf', a: 'setlight', data: { light: this.gama + '' } }))
    },
    defaultGama () {
      window.serverJS(JSON.stringify({ c: 'conf', a: 'resetlight' }))
      this.gama = window.serverJS(JSON.stringify({ c: 'conf', a: 'light' }))
    },
    btnVoice () {
      // if (!this.gameIng) {
      //   return
      // }
      this.voiceState = !this.voiceState
      window.serverJS(JSON.stringify({ c: 'conf', a: (this.voiceState ? 'openvoice' : 'closevoice') }))
    },
    setKeys () {
      // return
      this.$emit('setKeys')
    },
    setFree () {
      this.$emit('setFree')
    },
    openWin () {
      window.serverJS(JSON.stringify({ c: 'call', a: 'openurl', data: { url: 'https://pal.yunqidong.com/index/help', show: '1' } }))
    }
  }
}
</script>
<style scoped lang="less">
.left_menu {
  position: fixed;
  left: -300px;
  top: .48rem;
  width: 300px;
  height: calc(100% - .48rem);
  background-color: #000;
  z-index: 99;
  transition: all .3s ease-in-out;

  &.open {
    left: 0;
    transition: all .3s ease-in-out;
  }

  .in_box {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #494949;

    .head_tile {
      flex-shrink: 0;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .tils {
        font-size: 20px;
        color: #fff;
      }

      .wisl {
        display: flex;
        align-items: center;
        font-size: 14px;
        width: 130px;
        justify-content: center;
        border-radius: 4px;
        margin-top: 10px;
        color: #fff;
        background-color: #262626;
        height: 32px;
      }
    }

    .setting_box {
      background-color: #404040;
      box-sizing: border-box;

      flex: 1;

      .ga_num_box {
        margin: 0 auto;
        width: 260px;
        margin-top: 20px;
        height: 160px;
        box-sizing: border-box;
        padding: 20px;
        background-color: rgba(0, 0, 0, .2);

        .tils {
          font-size: 14px;
          color: #fff;
        }

        .input_s {
          display: block;
          margin-top: 10px;
          width: 100%;
        }

        .num_box {
          display: flex;
          margin-top: 5px;
          justify-content: space-between;
          font-size: 14px;
          color: #fff;
        }

        .auto_btn {
          display: block;
          margin: 0 auto;
          width: 100%;
          margin-top: 10px;
        }
      }

      .voice_box {
        width: 260px;
        margin: 0 auto;
        margin-top: 20px;

        .bn_itme {
          height: 60px;
          box-sizing: border-box;
          padding: 0 20px;
          display: flex;
          align-items: center;
          border-radius: 4px;
          background-color: rgba(0, 0, 0, .2);

          &:hover {
            background-color: #409EFF;
            cursor: pointer;
          }

          &.bn_itme {
            margin-top: 10px;
          }

          .btn_key {
            color: #52e88f;
            font-size: 16px;
            margin-left: auto;
          }

          .msk {
            width: 60px;
            height: 24px;
            display: block;
            margin-left: auto;
          }

          .tis {
            font-size: 16px;
            color: #fff;
            margin-left: 10px;
          }

          .icn_img {
            display: block;
            width: 18px;
            height: 22px;
          }

          .bt_icon {
            width: 26px;
            height: 26px;
            display: block;
            margin-left: 10px;
          }

          .icn_jt {
            display: block;
            width: 11px;
            height: 14px;
          }
        }

      }
    }

    .btn_link {
      position: absolute;
      width: 10px;
      height: 60px;
      background-color: #404040;
      top: 50%;
      right: -10px;
      border-radius: 0 4px 4px 0;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #262626;
        cursor: pointer;
      }

      .inc_si {
        font-size: 10px;
        display: block;
        color: #fff;
      }
    }
  }



}
</style>