<template>
  <div class="downPage">
    <div class="head_tile">
      <!-- <div class="box_siz"></div>
      <div class="box_siz"></div>
      <div class="box_siz"></div> -->
      <i class="el-icon-loading"></i>
      <div class="mst_tips">游戏启动中</div>
      <!-- <div class="box_siz"></div>
      <div class="box_siz"></div>
      <div class="box_siz"></div> -->
    </div>
    <div class="hed_tex">
      如果游戏启动失败，请确认你是否已安装游戏微端
    </div>
    <div>
      <el-button class="btn_sk" @click="dwonSever" type="primary">
        <div class="i_box">
          <img class="dow_icon" src="../assets/servericon.png">
          <div class="btn_font">下载私服服务端</div>
        </div>
      </el-button>
    </div>
    <div>
      <el-button class="btn_sk ks" @click="restartApp" type="primary">
        <div class="i_box">
          <img class="dow_icon" src="../assets/reload.png">
          <div class="btn_font">再次启动游戏</div>
        </div>
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "downPage",
  data () {
    return {

    }
  },
  methods: {
    restartApp(){
      this.$emit('restart')
    },
    dwonSever(){
      window.open('https://xemoss.wangkaguanli.com/palworldserver/release/palworldLaunch.rar')
    }
  }
}
</script>
<style lang="less" scoped>
.downPage {
  width: 100%;
  height: calc(100% - 1.68rem);
  background-color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .head_tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .6rem;
    color: #e5a700;

    .mst_tips {
      margin: 0 .2rem;
    }

    .box_siz {
      width: .08rem;
      height: .08rem;
      border-radius: 50%;
      background-color: #e5a700;
      margin: 0 .06rem;
    }
  }

  .hed_tex {
    font-size: .22rem;
    color: #fff;
    margin-top: .3rem;
    margin-bottom: .3rem;
  }

  .btn_sk {
    width: 3.2rem;
    height: .66rem;
    border: 0;
    display: block;
    margin-top: .2rem;

    .i_box {
      display: flex;
      align-items: center;
      justify-content: center;

      .dow_icon {
        width: .36rem;
        height: .34rem;
      }

      .btn_font {
        font-size: .2rem;
        margin-left: .1rem;
      }
    }
    &.ks{
      background-color: #000;
      border: .02rem solid #ffba00;
      color: #ffba00;
    }
  }

}
</style>